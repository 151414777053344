<template>
    <div id="container">
        <div id="k-line-chart" style="width:100%; height:700px"></div>
    </div>
</template>

<script>
import { init } from 'klinecharts';
import { chartOptions } from '@/libs/config';

export default {
    name: 'HelloWorld',
    data() {
        return {
            chart: null, // K线图实例
            ws: null, // WebSocket 实例
            lockReconnect: false, // 防止重复重连
            heartCheck: {
                timeout: 60000, // 1分钟心跳检测
                timeoutObj: null,
                serverTimeoutObj: null,
                reset() {
                    clearTimeout(this.timeoutObj);
                    clearTimeout(this.serverTimeoutObj);
                    return this;
                },
                start() {
                    this.timeoutObj = setTimeout(() => {
                        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                            this.ws.send('ping');
                        }
                        this.serverTimeoutObj = setTimeout(() => {
                            this.ws.close(); // 超时关闭 WebSocket
                        }, this.timeout);
                    }, this.timeout);
                }
            }
        };
    },
    mounted() {
        // 初始化图表
        this.chart = init('k-line-chart', chartOptions);

        // 初始化图表
        // this.chart.createIndicator('MA', true, { id: 'candle_pane' })
        // this.chart.createIndicator('VOL')
        // // 为图表添加数据
        this.$http
            // .get(`https://care-s.recallg.cn/test-data.php`)
            .get(`/api/index/klines`)
            .then((response) => {
                this.chart.applyNewData(response.data.data);
            })
            .catch((error) => {
                console.error('加载更多数据失败:', error);
            });
        // this.chart.applyNewData(this.genDatas());
        this.setType('area');

        // 设置加载更多回调
        this.chart.loadMore(this.loadMoreCallback);

        // 初始化 WebSocket
        const wsUrl = `wss://kline-s.recallg.com/ws/second/${this.guid()}`;
        this.createWebSocket(wsUrl);

        // 窗口关闭事件
        window.onbeforeunload = () =>
        {
            if (this.ws)
            {
                this.ws.close();
            }
        };
    },
    beforeDestroy()
    {
        if (this.ws)
        {
            this.ws.close();
        }
    },
    methods:
        {
        // 生成 GUID
        guid()
        {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
                const r = (Math.random() * 16) | 0;
                const v = c === 'x' ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },

        // 生成图表数据
        genDatas(timestamp = new Date().getTime(), length = 800)
        {
            let basePrice = 5000;
            timestamp = Math.floor(timestamp / 1000 / 60) * 60 * 1000 - length * 60 * 1000;
            const dataList = [];
            for (let i = 0; i < length; i++) {
                const prices = [];
                for (let j = 0; j < 4; j++) {
                    prices.push(basePrice + Math.random() * 60 - 30);
                }
                prices.sort();
                const open = +(prices[Math.round(Math.random() * 3)].toFixed(2));
                const high = +(prices[3].toFixed(2));
                const low = +(prices[0].toFixed(2));
                const close = +(prices[Math.round(Math.random() * 3)].toFixed(2));
                const volume = Math.round(Math.random() * 100) + 10;
                const turnover = ((open + high + low + close) / 4) * volume;
                dataList.push({ timestamp, open, high, low, close, volume, turnover });

                basePrice = close;
                timestamp += 60 * 1000;
            }
            return dataList;
        },

        // 设置图表类型
        setType(type) {
            this.chart.setStyles({
                candle: { type }
            });
        },

        // 加载更多数据的回调函数
        loadMoreCallback(timestamp) {
            console.log('加载更多数据，时间戳: ', timestamp);
            this.$http
                .get(`/api/index/kline/load/mores?timestamp=${timestamp}`)
                .then((response) => {
                    this.chart.applyMoreData(response.data);
                })
                .catch((error) => {
                    console.error('加载更多数据失败:', error);
                });
        },

        // 初始化 WebSocket
        createWebSocket(url) {
            try {
                if ('WebSocket' in window) {
                    this.ws = new WebSocket(url);
                }
                this.initEventHandle(url);
            } catch (error) {
                this.reconnect(url);
                console.error('WebSocket 初始化失败:', error);
            }
        },

        // 初始化 WebSocket 事件
        initEventHandle(url) {
            this.ws.onopen = () => {
                console.log('WebSocket 连接成功');
                this.heartCheck.reset().start();
            };

            this.ws.onmessage = (event) => {
                this.heartCheck.reset().start();
                if (event.data !== 'ping') {
                    const data = JSON.parse(event.data)[0];
                    data.timestamp = Number(data.timestamp + '000');
                    this.chart.updateData(data);
                }
            };

            this.ws.onerror = () => {
                console.error('WebSocket 连接错误');
                this.reconnect(url);
            };

            this.ws.onclose = () => {
                console.log('WebSocket 连接关闭');
                this.reconnect(url);
            };
        },

        // WebSocket 重连逻辑
        reconnect(url) {
            if (this.lockReconnect) return;
            this.lockReconnect = true;
            setTimeout(() => {
                console.log('尝试重连 WebSocket...');
                this.createWebSocket(url);
                this.lockReconnect = false;
            }, 2000);
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}
</style>
