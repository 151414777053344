import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';

// 将 axios 挂载到 Vue 原型上
Vue.prototype.$http = axios;
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
