export const chartOptions = {
    locale: "zh-CN",
    timeValue: '1',
    // 网格线
    grid: {
        show: true,
        horizontal: {
            show: true,
            size: 1,
            color: '#EDEDED',
            style: 'dashed',
            dashedValue: [2, 2]
        },
        vertical: {
            show: true,
            size: 1,
            color: '#EDEDED',
            style: 'dashed',
            dashedValue: [2, 2]
        }
    },
    candle: {
        type: 'area',
        // 面积图
        area: {
            lineSize: 2,
            lineColor: '#2196F3',
            value: 'close',
            backgroundColor: [{
                offset: 0,
                color: 'rgba(33, 150, 243, 0.01)'
            }, {
                offset: 1,
                color: 'rgba(33, 150, 243, 0.2)'
            }]
        }
    },
    priceMark: {
        show: true,
        // 最高价标记
        high: {
            show: true,
            color: '#D9D9D9',
            textOffset: 5,
            textSize: 10,
            textFamily: 'Helvetica Neue',
            textWeight: 'normal'
        },
        // 最低价标记
        low: {
            show: true,
            color: '#D9D9D9',
            textOffset: 5,
            textSize: 10,
            textFamily: 'Helvetica Neue',
            textWeight: 'normal',
        },
        // 最新价标记
        last: {
            show: true,
            upColor: '#2DC08E',
            downColor: '#F92855',
            noChangeColor: '#888888',
            line: {
                show: true,
                // 'solid' | 'dashed'
                style: 'dashed',
                dashedValue: [4, 4],
                size: 1
            },
            text: {
                show: true,
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                size: 12,
                paddingLeft: 4,
                paddingTop: 4,
                paddingRight: 4,
                paddingBottom: 4,
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderSize: 0,
                borderColor: 'transparent',
                borderDashedValue: [2, 2],
                color: '#FFFFFF',
                family: 'Helvetica Neue',
                weight: 'normal',
                borderRadius: 2
            }
        }
    },
    // 提示
    tooltip: {
        // 'always' | 'follow_cross' | 'none'
        showRule: 'always',
        // 'standard' | 'rect'
        showType: 'standard',
        // 自定义显示，可以是回调方法也可以是数组，当是一个方法时，需要返回一个数组
        // 数组的子项类型为 { title, value }
        // title和value可以是字符串或者对象，对象类型为 { text, color }
        // title 或者 title.text 可以是国际化的 key，
        // value 或者 value.text 支持字符串模版
        // 例如：想显示时间，开盘和收盘，配置[{ title: 'time', value: '{time}' }, { title: 'open', value: '{open}' }, { title: 'close', value: '{close}' }]
        custom: null,
        defaultValue: 'n/a',
        rect: {
            // 'fixed' | 'pointer'
            position: 'fixed',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 6,
            offsetLeft: 10,
            offsetTop: 8,
            offsetRight: 10,
            offsetBottom: 8,
            borderRadius: 4,
            borderSize: 1,
            borderColor: '#f2f3f5',
            color: '#FEFEFE'
        },
        text: {
            size: 12,
            family: 'Helvetica Neue',
            weight: 'normal',
            color: '#D9D9D9',
            marginLeft: 10,
            marginTop: 8,
            marginRight: 6,
            marginBottom: 0
        },
        // 示例：
        // [{
        //   id: 'icon_id',
        //   position: 'left', // 类型有'left'，'middle'，'right'
        //   marginLeft: 8,
        //   marginTop: 6,
        //   marginRight: 0,
        //   marginBottom: 0,
        //   paddingLeft: 1,
        //   paddingTop: 1,
        //   paddingRight: 1,
        //   paddingBottom: 1,
        //   icon: '\ue900',
        //   fontFamily: 'iconfont',
        //   size: 12,
        //   color: '#76808F',
        //   backgroundColor: 'rgba(33, 150, 243, 0.2)',
        //   activeBackgroundColor: 'rgba(33, 150, 243, 0.4)'
        // }]
        icons: []
    }
}
